import React, { useContext, useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import DatePicker from "react-datepicker"
import moment from "moment"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import ToggleKeyboard from "../components/ToggleKeyboard"
import ToggleMute from "../components/ToggleMute"

export default function RegistrationFormA2() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")
  const [birthDate, setBirthDate] = useState(new Date("2000-01-01"))
  const [singaporePrDate, setSingaporePrDate] = useState(new Date())
  const API_URL = process.env.GATSBY_API_URL

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
    console.log("Inputs changed", inputs, user)
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value.toUpperCase(),
    })
    console.log(user)
  }

  useEffect(() => {
    if (inputName === "mobile_number") {
      setLayoutName("numeric")
    } else {
      setLayoutName("default")
    }
  }, [inputName])

  // Client-side Runtime Data Fetching
  const [referrals, setReferrals] = useState([])
  useEffect(() => {
    //get data from primarius
    fetch(API_URL + "/referrals")
      .then(response => response.json()) //parse JSON from request
      .then(resultData => {
        setReferrals(resultData)
      })
  }, [])

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="d-flex flex-column">
        <h1 className="display-5 text-center font-weight-bold mt-4">
          A. Personal Particulars (pt 2)
        </h1>

        <div className="row">
          <div className="col-12">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-3 mt-4">
                Residential Address:
              </h3>
              <div className="col-9">
                <input
                  type="text"
                  name="address"
                  onFocus={() => setInputName("address")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "7em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  value={user.address}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-3 mt-4">
                Residential Telephone No:
              </h3>
              <div className="col-9">
                <input
                  type="text"
                  name="residential_number"
                  onFocus={() => setInputName("residential_number")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "7em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  value={user.residential_number}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-3 mt-4">
                Mobile No:
              </h3>
              <div className="col-9">
                <input
                  type="text"
                  name="mobile_number"
                  onFocus={() => setInputName("mobile_number")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "7em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  value={user.mobile_number}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-3 mt-4">
                Person you've
                <br />
                contacted:
              </h3>
              <div className="col-9">
                {/* <input
                  type="text"
                  name="mobile_number"
                  onFocus={() => setInputName("mobile_number")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "5em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  value={user.mobile_number}
                  onChange={e => saveField(e)}
                /> */}
                <select
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "7em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  name="person_in_charge_id"
                  value={user.person_in_charge_id}
                  onChange={e => saveField(e)}
                >
                  <option key={0} value={null}>
                    -- Select option --
                  </option>
                  {referrals.map(referral => (
                    <option key={referral.id} value={referral.id}>
                      {referral.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row-reverse fixed-top p-4">
            <ToggleKeyboard
              isActive={user.isKeyboardActive}
              onClick={() => {
                saveUser({
                  ...user,
                  isKeyboardActive: !user.isKeyboardActive,
                })
              }}
            />
            <ToggleMute
              isActive={user.isSoundActive}
              onClick={() => {
                saveUser({
                  ...user,
                  isSoundActive: !user.isSoundActive,
                })
                if (user.isSoundActive) {
                  user.audio && user.audio.pause && user.audio.pause()
                } else {
                  user.audio && user.audio.play && user.audio.play()
                }
              }}
            />
          </div>
          <div className="d-flex flex-row-reverse fixed-bottom p-4">
            <Button
              text="Next"
              size="medium"
              disabled={
                !user.address ||
                !user.residential_number ||
                !user.mobile_number ||
                !user.person_in_charge_id
              }
              onClick={() => {
                saveDraftUser(user)
                navigate("/registration-form-b")
              }}
            />
            <Button
              size="medium"
              text="Back"
              onClick={() => navigate("/registration-form-a")}
            />
          </div>
          {user.isKeyboardActive && (
            <div
              className="fixed-bottom w-50 p-4"
              style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
            >
              <Keyboard
                keyboardRef={r => (keyboard.current = r)}
                inputName={inputName}
                layoutName={layoutName}
                onChangeAll={onChangeAll}
                onKeyPress={onKeyPress}
                layout={{
                  default: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} Q W E R T Y U I O P [ ] \\",
                    "{lock} A S D F G H J K L ; ' {enter}",
                    "{shift} Z X C V B N M , . / {shift}",
                    ".com @ {space}",
                  ],
                  shift: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : " {enter}',
                    "{shift} Z X C V B N M < > ? {shift}",
                    ".com @ {space}",
                  ],
                  numeric: ["1 2 3 4 5 6 7 8 9 0 {bksp}"],
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Background>
  )
}
